/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import isNull from 'lodash/isNull';

import { styleConfig } from '../../config/config';

import Title from './Common/Title';

const handleClick = () => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'email click', {
      event_category: 'enquiry',
    });
  }
};

const ContactImageText: React.FunctionComponent<ImageTextProps> = (props) => {
  const { panelSubTitle, panelTitle } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: 'block',
      width: '100%',
      position: 'relative',
      fontWeight: 600,
    },
    content: {
      padding: 75,
      position: 'relative',
      height: '100%',
      width: '100%',
      minHeight: 500,
      background: styleConfig.color.bg.darkgrey,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      [theme.breakpoints.down('sm')]: {
        padding: 25,
      },
    },
    image: {
      height: '100%',
    },
    information: {
      textAlign: 'center',
      marginTop: '4rem',
      fontWeight: 600,

      '& strong': {
        color: styleConfig.color.text.white,
      },

      '& a': {
        color: styleConfig.color.text.gold,
      },
    },

    intro: {
      marginBottom: 25,
    },
    noPadding: {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
    listItem: {
      marginBottom: 50,
      letterSpacing: 1,

      '& > p': {
        color: styleConfig.color.text.white,
      },
    },
    listTitle: {
      fontWeight: 800,
      color: styleConfig.color.text.white,
    },
    listLink: {
      color: styleConfig.color.text.gold,
      textDecoration: 'underline',
    },
  }));

  const placeholder = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "car-placeholder.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(quality: 80, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  const roundelImage = getImage(placeholder.file);
  const roundelBg = convertToBgImage(roundelImage);
  const classes = useStyles();

  return (
    <Box component="section" className={classes.root} id="contactDetails">
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className={classes.content}>
            {!isNull(panelTitle) && !isNull(panelSubTitle) && panelTitle && (
              <Title
                component="h2"
                alignment="center"
                isSubtitle
                subtitleText={panelSubTitle}
                subtitleType="subtitle1"
                titleColor={styleConfig.color.text.white}
                titleHeavy
                titleSize="h2"
                titleText={panelTitle}
              />
            )}

            <Box component="div" className={classes.information}>
              <div className={classes.listItem}>
                <Typography className={classes.intro}>
                  If you prefer, please send an email, call or even write to us with your questions
                  or enquiry.
                </Typography>
              </div>

              <div className={classes.listItem}>
                <Typography className={classes.listTitle} variant="body1">
                  Office hours
                </Typography>

                <Typography component="span" color="primary" variant="body1">
                  9.30 am - 5 pm, Monday - Friday.
                </Typography>
              </div>

              <div className={classes.listItem}>
                <Typography className={classes.listTitle} variant="body1">
                  Address
                </Typography>

                <Typography component="span" color="primary" variant="body1">
                  Morgan Highfield & Land, 92 Baslow Road, Sheffield, S17 4DQ
                </Typography>
              </div>

              <div className={classes.listItem}>
                <Typography className={classes.listTitle} variant="body1">
                  Email
                </Typography>

                <a
                  href="mailto:info@prestige-car-leasing.co.uk"
                  onClick={() => handleClick()}
                  className={classes.listLink}
                >
                  info@prestige-car-leasing.co.uk
                </a>
              </div>

              <div className={classes.listItem}>
                <Typography className={classes.listTitle} variant="body1">
                  Telephone
                </Typography>

                <a href="tel:0114 2504030" className={classes.listLink}>
                  0114 2504030
                </a>
              </div>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <BackgroundImage
            durationFadeIn={250}
            Tag="section"
            className={classes.image}
            {...roundelBg}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

interface ImageTextProps {
  panelSubTitle?: string;
  panelTitle: string;
  isReverse?: boolean;
}

export default ContactImageText;
