import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

// Components
import FormTitle from './FormTitle';

const HeaderForm: React.SFC<HeaderFormProps> = (props) => {
  const { title, preTitle } = props;

  // Styles
  const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
      padding: 50,

      [theme.breakpoints.down('md')]: {
        padding: '75px 0 0',
      },

      [theme.breakpoints.down('sm')]: {
        padding: '50px 0 0',
      },
    },
  }));

  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <FormTitle title={title} preTitle={preTitle} variant="h1" />
    </Box>
  );
};

interface HeaderFormProps {
  title: string;
  preTitle?: string;
}

export default HeaderForm;
