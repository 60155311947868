import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { styleConfig } from '../../config/config';

const FormTitle: React.FunctionComponent<FormTitleProps> = (props) => {
  const { title, preTitle, variant } = props;

  // Styles
  const useStyles = makeStyles(() => ({
    preTitle: {
      color: styleConfig.color.bg.gold,
      fontSize: 20,
      margin: 0,
    },
    title: {
      color: '#2B2B2B',
      fontSize: 50,
      fontWeight: 600,
      fontFamily: 'Bai Jamjuree',
      lineHeight: 1,
      marginBottom: 50,
    },
  }));

  const classes = useStyles();

  return (
    <Box textAlign="center">
      {preTitle && (
        <Typography component="p" className={classes.preTitle}>
          {preTitle}
        </Typography>
      )}
      {title && (
        <Typography component={variant || 'h2'} className={classes.title}>
          {title}
        </Typography>
      )}
    </Box>
  );
};

interface FormTitleProps {
  title: string;
  preTitle?: string;
  variant?: 'h1' | 'h2';
}

export default FormTitle;
