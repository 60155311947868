import React from 'react';
import { graphql } from 'gatsby';

import { decodeObjectSpecialCharacters } from '../utils/utils';

import ContactForm from '../components/ContactForm';
import ContactImageText from '../components/ContactImageText';
import HeaderForm from '../components/HeaderForm';
import Hero from '../components/Hero';
import LayoutContact from '../components/LayoutContact';
import SEO from '../components/SEO';

const Contact = (props) => {
  const { data } = props;
  const yoastMeta = decodeObjectSpecialCharacters(data.wordpressPage.yoast_meta);

  return (
    <main>
      <SEO
        description={yoastMeta.yoast_wpseo_metadesc}
        ogDesc={yoastMeta.yoast_wpseo_facebook_description}
        ogImg={yoastMeta.yoast_wpseo_facebook_image}
        ogTitle={yoastMeta.yoast_wpseo_facebook_title}
        title={yoastMeta.yoast_wpseo_title}
        twitterDesc={yoastMeta.yoast_wpseo_twitter_description}
        twitterImg={yoastMeta.yoast_wpseo_twitter_image}
        twitterTitle={yoastMeta.yoast_wpseo_twitter_title}
      />

      <LayoutContact>
        <Hero
          isCtaAppended
          buttons={data.wordpressPage.acf.buttons}
          content={data.wordpressPage.acf.content}
          headline={data.wordpressPage.page_meta_fields.title || data.wordpressPage.title.rendered}
          slides={data.wordpressPage.acf.slides}
          subtitle={data.wordpressPage.acf.pre_title}
          isSearchButton
        />
        <HeaderForm title="Morgan Highfield & Land" preTitle="Get in touch with" />
        <ContactForm />
        <ContactImageText panelTitle="Contact Information" />
      </LayoutContact>
    </main>
  );
};

export default Contact;

export const query = graphql`
  query ContactPageQuery($id: Int) {
    wordpressPage(wordpress_id: { eq: $id }) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      page_meta_fields {
        title
      }
      title
      acf {
        buttons {
          button {
            title
            url
            target
          }
        }
        content
        slides {
          highlight {
            post_meta_fields {
              capid
              brand_title
              title
              variant_url
              __typename
            }
          }
          image {
            alt_text
            localFile {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  quality: 80
                  height: 856
                  width: 1600
                  transformOptions: {
                    cropFocus: CENTER
                    duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                  }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;
