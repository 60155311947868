import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const FormBox: React.FunctionComponent<FormBoxProps> = ({ children }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      padding: '75px',
      flexGrow: 1,
      [theme.breakpoints.down('md')]: {
        padding: '75px',
      },

      [theme.breakpoints.down('sm')]: {
        padding: '25px',
      },
    },
  }));

  const classes = useStyles();

  return <Paper className={classes.root}>{children}</Paper>;
};

interface FormBoxProps {
  children: React.ReactNode;
}

export default FormBox;
