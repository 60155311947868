import React, { useState } from 'react';
import { Container, Grid, Theme, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { Link } from 'gatsby';
import * as Yup from 'yup';
import clsx from 'clsx';

import { tooltipValues } from '../constants';

import { styleConfig } from '../../config/config';

import FormBox from './FormBox';
import InputSelect from './InputSelect';
import InputText from './InputText';
import Loading from './LeaseBuilder/CustomiseLease/Steps/Loading';
import MuiButton from './MuiButton';

const ContactForm: React.FunctionComponent = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [leaseType, setLeaseType] = useState('business');

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      marginBottom: 150,
      flexGrow: 1,
    },
    title: {
      borderBottom: '1px #ddd solid',
      color: theme.palette.primary.dark,
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 30,
      paddingBottom: 20,
      width: 'calc(100% - 45px)',
    },
    marginTop: {
      marginTop: 50,
      [theme.breakpoints.down('md')]: {
        marginTop: 25,
      },
    },
    loadingRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 300,
    },
    loadingMessage: {
      fontFamily: 'Bai Jamjuree',
      fontSize: 20,
      fontWeight: 600,
      marginTop: 25,
    },
    errorTitle: {
      fontSize: 40,
      fontWeight: 600,
    },
    messageRoot: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 300,
    },
    submitTitle: {
      fontSize: 40,
      fontWeight: 600,
      marginBottom: 15,
    },
    message: {
      fontSize: 20,
      fontWeight: 600,
      maxWidth: 540,
      textAlign: 'center',
      marginBottom: 60,

      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& a': {
        marginBottom: 30,
      },
    },
    footer: {
      marginTop: 100,
      '& button': {
        marginRight: 50,
      },
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: 'auto',
      marginRight: 15,
    },
    intro: {
      color: styleConfig.color.text.gray,
      marginBottom: 50,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 25,
      },
    },
  }));

  const classes = useStyles();

  // Form options
  const titles = ['Please select', 'Mr', 'Mrs', 'Miss', 'Ms'];
  const leaseTypeMenuItems = ['business', 'personal'];
  const tradingStyles = ['Limited Company', 'LLP', 'PLC', 'Partnership', 'Sole Trader'];
  const contactPreferences = ['Email', 'Telephone'];

  // Field validation
  const numberRegExp = /^\(?0( *\d\)?){9,10}$/;

  // Validation schema for Formik
  const validationSchemaBusiness = Yup.object().shape({
    businessName: Yup.string().required(),
    tradingStyle: Yup.string().required(),
    email: Yup.string().email().required(),
    message: Yup.string().required(),
    title: Yup.string().oneOf(['Mr', 'Mrs', 'Miss', 'Ms']).required(),
    forename: Yup.string().required(),
    surname: Yup.string().required(),
    phoneNumber: Yup.string().matches(numberRegExp).required(),
  });

  // Validation schemas for Formik
  const validationSchemaPersonal = Yup.object().shape({
    email: Yup.string().email().required(),
    message: Yup.string().required(),
    title: Yup.string().oneOf(['Mr', 'Mrs', 'Miss', 'Ms']).required(),
    forename: Yup.string().required(),
    surname: Yup.string().required(),
    phoneNumber: Yup.string().matches(numberRegExp).required(),
  });

  const validationSchema =
    leaseType === 'personal' ? validationSchemaPersonal : validationSchemaBusiness;

  return (
    <Formik
      initialValues={{
        leaseType: 'business',

        email: '',
        forename: '',

        maintenance: 'No',
        message: '',
        phoneNumber: '',
        surname: '',
        title: 'Please select',
        contactPreferences: 'Email',

        businessName: '',
        tradingStyle: 'Limited Company',
        yearsTrading: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // The payload for the form submission along with lease data from the global state
        const payload = {
          // Lease fields
          ['Lease Type']: values.leaseType,

          // Personal fields
          ['Title']: values.title,
          ['Forename']: values.forename,
          ['Surname']: values.surname,
          ['Phone Number']: values.phoneNumber,
          ['Email']: values.email,
          ['Message']: values.message,
          ['Contact Preferences']: values.contactPreferences,

          // Business fields
          ['Business Name']: leaseType === 'business' ? values.businessName : 'n/a',
          ['Trading Style']: leaseType === 'business' ? values.tradingStyle : 'n/a',
          ['Years Trading']: leaseType === 'business' ? values.yearsTrading : 'n/a',
        };

        // Scroll to the top to for the view change
        typeof window !== 'undefined' &&
          window.scrollTo({
            top: 350,
            left: 0,
            behavior: 'smooth',
          });

        setIsSubmitting(true);

        return fetch(`${process.env.GATSBY_SITE_URL}/wp-json/wp_mail/v1/enquiry`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status === 200) {
              setIsSubmitting(false);
              setIsSubmit(true);

              if (typeof window !== 'undefined' && window.gtag) {
                window.gtag('event', 'click', {
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  event_category: 'enquiry',
                });
              }
            }
          })
          .catch(() => {
            setIsSubmitError(true);
          });
      }}
    >
      {({ handleChange, handleBlur, values, errors, setFieldValue }) => (
        <Box className={classes.root} id="contactForm">
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <FormBox>
                <React.Fragment>
                  {isSubmitting && !isSubmitError && <Loading message="Please wait..."></Loading>}

                  {isSubmitError && (
                    <Box className={classes.messageRoot}>
                      <Typography color="error" component="h3" className={classes.errorTitle}>
                        Error
                      </Typography>
                      <Typography className={classes.message}>
                        There was an error with your request. Please try again later or get in touch
                      </Typography>
                    </Box>
                  )}

                  {isSubmit && !isSubmitError && (
                    <Box className={classes.messageRoot}>
                      <Typography
                        id="form-contact-confirm"
                        className={classes.submitTitle}
                        component="h3"
                      >
                        Thank you {values.forename && values.forename}{' '}
                        {values.surname && values.surname}
                      </Typography>
                      <Typography className={classes.message} color="primary">
                        A member of our team will respond to your enquiry as soon as possible.
                      </Typography>

                      <div className={classes.buttons}>
                        <Link aria-label="Back to homepage" to="/">
                          <MuiButton
                            aria-label="Back to homepage"
                            name="back-to-home"
                            variant="text"
                          >
                            Back to homepage
                          </MuiButton>
                        </Link>
                      </div>
                    </Box>
                  )}

                  {!isSubmitting && !isSubmitError && !isSubmit && (
                    <Form name="contact">
                      <Typography variant="h3" className={classes.title}>
                        Lease Options
                      </Typography>

                      <Grid container spacing={3}>
                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                          <InputSelect
                            capitalize
                            isPrimary
                            disabled={isSubmitting}
                            fullWidth
                            handleChange={(val) => {
                              setFieldValue('leaseType', val);
                              setLeaseType(val);
                            }}
                            label
                            labelText="Lease Type"
                            menuItems={leaseTypeMenuItems}
                            name="leaseType"
                            value={values.leaseType}
                            marginBottom={25}
                          />
                        </Grid>
                      </Grid>

                      {values.leaseType === 'business' && (
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            className={clsx(classes.title, classes.marginTop)}
                          >
                            Business Details
                          </Typography>

                          <Grid container spacing={3}>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                              <InputText
                                disabled={isSubmitting}
                                error={typeof errors.businessName === 'string'}
                                fullWidth
                                marginBottom={25}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                isLabel
                                labelText="Business Name"
                                name="businessName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder="Enter name"
                                type="text"
                                value={values.businessName}
                                isTooltip
                                tooltipContent={tooltipValues.businessName}
                              />
                            </Grid>

                            <Grid item lg={4} md={6} sm={6} xs={12}>
                              <InputSelect
                                disabled={isSubmitting}
                                fullWidth
                                handleChange={(val) => {
                                  if (val !== values.tradingStyle) {
                                    setFieldValue('tradingStyle', val);
                                  }
                                }}
                                label
                                labelText="Type of business"
                                menuItems={tradingStyles}
                                name="tradingStyle"
                                value={values.tradingStyle}
                                marginBottom={25}
                              />
                            </Grid>

                            <Grid item lg={4} md={6} sm={6} xs={12}>
                              <InputText
                                disabled={isSubmitting}
                                error={typeof errors.yearsTrading === 'string'}
                                fullWidth
                                marginBottom={25}
                                inputProps={{
                                  min: 0,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                isLabel
                                labelText="Years trading"
                                name="yearsTrading"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="number"
                                value={values.yearsTrading}
                                placeholder="Enter years"
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      )}

                      <Typography variant="h3" className={clsx(classes.title, classes.marginTop)}>
                        Personal Details
                      </Typography>

                      <Grid container spacing={3}>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <InputSelect
                            error={typeof errors.title === 'string'}
                            disabled={isSubmitting}
                            fullWidth
                            handleChange={(val) => {
                              if (val !== values.title) {
                                setFieldValue('title', val);
                              }
                            }}
                            label
                            labelText="Title"
                            menuItems={titles}
                            name="title"
                            value={values.title}
                            marginBottom={25}
                          />
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <InputText
                            disabled={isSubmitting}
                            error={typeof errors.forename === 'string'}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            isLabel
                            labelText="Forename"
                            name="forename"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="string"
                            value={values.forename}
                            placeholder="Enter forename"
                            marginBottom={25}
                          />
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <InputText
                            disabled={isSubmitting}
                            error={typeof errors.surname === 'string'}
                            fullWidth
                            isLabel
                            labelText="Surname"
                            name="surname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="string"
                            value={values.surname}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Enter surname"
                            marginBottom={25}
                          />
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <InputText
                            disabled={isSubmitting}
                            error={typeof errors.phoneNumber === 'string'}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            isLabel
                            labelText="Number"
                            name="phoneNumber"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="string"
                            value={values.phoneNumber}
                            placeholder="Enter phone number"
                            marginBottom={25}
                          />
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <InputText
                            disabled={isSubmitting}
                            error={typeof errors.email === 'string'}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            isLabel
                            labelText="Email"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="string"
                            value={values.email}
                            placeholder="Enter email"
                            marginBottom={25}
                          />
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <InputSelect
                            disabled={isSubmitting}
                            fullWidth
                            handleChange={(val) => {
                              if (val !== values.contactPreferences) {
                                setFieldValue('contactPreferences', val);
                              }
                            }}
                            label
                            labelText="Contact preferences"
                            menuItems={contactPreferences}
                            name="contactPreferences"
                            value={values.contactPreferences}
                            marginBottom={25}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <InputText
                            disabled={isSubmitting}
                            error={typeof errors.message === 'string'}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            isLabel
                            labelText="Your message or question"
                            multiline
                            name="message"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Enter your message or question"
                            rows={8}
                            type="string"
                            value={values.message}
                            marginBottom={25}
                          />
                        </Grid>
                      </Grid>

                      <div className={classes.btnWrapper}>
                        <Grid item xs={12}>
                          <MuiButton
                            aria-label="Submit"
                            disabled={isSubmitting}
                            height={61}
                            name="submit-contact-form"
                            type="submit"
                            variant="contained"
                          >
                            Submit
                          </MuiButton>
                        </Grid>
                      </div>
                    </Form>
                  )}
                </React.Fragment>
              </FormBox>
            </Grid>
          </Container>
        </Box>
      )}
    </Formik>
  );
};

export default ContactForm;
